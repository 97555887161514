export const QuestionData = [
  "영화 🎬",
  "음악 🎧",
  "물건 🛍",
  "사건 📆",
  "책 📚",
  "공부 📖",
  "후회 💩",
  "도전 🚀",
  "사람 👫",
  "사랑 ❤️",
  "방송 📺",
  "행복 ☘",
  "슬픔 😢",
  "장소 🗺",
  "음식 😋",
  "동물 🐘",
  "선물 🎁",
  "갈등 🔥"
]

export const ColorChip = [
  "#392AEC",
  "#E8E7F0",
  "#CAC7EF",
  "#9091ED",
  "#6D79EC",
  "#fff"
]